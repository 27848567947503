

.all-content{
    background-color: white;
    margin-bottom: 490px;
}

.navigation{
    background-color: #f6f6f6;
    height: 150px;
align-items: center;
display: flex;
}
.navigation span{
    display: inline-block;
    padding: 0 2px;
    font-weight: 400;
}
.navigation span a{
    text-decoration: none;
    color: rgb(83, 81, 81);

}
.content .productes p{
    float: left;
    font-size: 12px;
}
.content .productes #option{
    float: right;
  
}
.content .productes #option select{
    border: none;
    outline: none;
}
.content .productes #option select option{
    padding: 30px 0;
    background-color: #f6f6f6;
}

.sidebar #sidebar ul li{
    list-style: none;
    margin-bottom: 15px;
}
.sidebar #sidebar ul li a{
    text-decoration: none;
    color: rgb(51, 50, 50);
}
.sidebar #sidebar h3{
    margin: 20px 0;
}
.content .top-products .top-box img{
width: 70px;
height: 70px;
float: left;
margin-right: 15px;
}
.content .top-products .top-box{
    margin-bottom: 20px;
}
.all-content .sidebar-search input{
    width: 100%;
    padding: 10px;
    border: none;
    border-bottom: 1px solid black;
   
    outline: none;
}
.all-content .sidebar-search{
    position: relative;
}
.all-content .sidebar-search button{
    padding: 5px;
    position: absolute;
    right: 0;
    top: 0;
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
}
.all-content .sidebar-search button i{
    font-size: 20px;
}

.all-content .paragraph{
    margin-top: 30px;
}
.all-content .paragraph h3{
    margin-bottom: 20px;
}
.all-content .paragraph p{
    line-height: 30px;
    font-size: 13px;
    margin-bottom: 20px;
}
.all-content .paragraph img{
    width: 100%;
}
.content .productes{
    margin-top: 20px;
    padding: 0 10px;
}
.content .box-prod .accesory .inner-pro img{
    width: 100%;
    height: 310px;
    transition: 0.5s ease all;
    cursor: pointer;
}
.content  .effect{
    position: relative;
    overflow: hidden;
}
.content  .effect ul{
    position: absolute;
    bottom: 0;
    right: -100%;
    background-color: white;
    transition: 1s ease all;
    padding: 5px;
   
}
.content  .effect:hover ul{
    right: 0;
    transition: 1s ease all;
}
.content  .effect ul li{
    display: inline-block;
    list-style: none;
    margin-right: 15px;

}
.content  .effect ul li a{
    text-decoration: none;
    color: black;
}
.content .box-prod .accesory .inner-pro img:hover{
    filter: brightness(80%);
    transition: 0.5s ease all;

}
.content .box-prod .accesory .inner-pro h4{
    color: rgb(73, 70, 70);
    margin-top: 20px;
}
.content .box-prod .accesory{
    padding: 0 10px;
}
.content .box-prod .accesory .inner-pro p{
    font-size: 16px;
    float: right;
    margin-top: -20px;
}
.content .box-prod .accesory .inner-pro button{
    background:none;
    border: none;
    position: relative;
    cursor: pointer;
}
.content .box-prod .accesory .inner-pro button::before{
    content: '';
    width: 0;
    height: 1px;
    background-color: black;
    position: absolute;
    bottom: -5px;
    left: 0;
    transition: 0.5s ease all;
}
.content .box-prod .accesory .inner-pro button:hover::before{
    width: 100%;
    transition: 0.5s ease all;
}
.sub-prods{
    margin-top: 100px;
    padding-bottom: 30px;
}
.sub-prods .inner-sub img{
width: 100%;
transition: 1s ease all;
}
.sub-prods .inner-sub img:hover{
    filter: brightness(80%);
    cursor: pointer;
    transition: 1s ease all;
}
.sub-prods .sub-col{
    padding: 0 10px 0 10px;
}
.sub-prods .number{
    margin-top: 50px;
}
.sub-prods .number ul li{
    list-style: none;
    display: inline-block;
    margin-right: 30px;
}
.sub-prods .number ul li a{
    text-decoration: none;
    color: black;
    cursor: pointer;
    position: relative;
}
.sub-prods .number ul li a::before{
    content: '';
    width: 0;
    height: 1px;
    background-color: black;
    position: absolute;
    bottom: -5px;
    left: 0;
    transition: 0.5s ease all;
}
.sub-prods .number ul li a:hover::before{
    width: 100%;
    transition: 0.5s ease all;
}


.content .sub-prods .inner-sub h4{
    color: rgb(73, 70, 70);
    margin-top: 20px;
}

.content .sub-prods .inner-sub p{
    font-size: 16px;
    float: right;
    margin-top: -20px;
}
.content .sub-prods .inner-sub button{
    background:none;
    border: none;
    position: relative;
    cursor: pointer;
}
.content .sub-prods .inner-sub button::before{
    content: '';
    width: 0;
    height: 1px;
    background-color: black;
    position: absolute;
    bottom: -5px;
    left: 0;
    transition: 0.5s ease all;
}
.content .sub-prods .inner-sub button:hover::before{
    width: 100%;
    transition: 0.5s ease all;
}


@media only screen and (max-width:992px){
    .all-content{
      
        margin-bottom: 0;
    }
    .content .box-prod .accesory{
        margin-bottom: 30px;
    }
}