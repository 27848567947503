.menu{
    overflow: hidden;
    padding: 0 0 15px 0;
  width: 100%;
    background-color: #fff;
  
   
}
.menu .list span img{
    width: 90px;
}
.menu .list{
    margin-top: 25px;
    position: relative;
   
}
.menu .list span{
    float:left ;
    margin-right: 10px;
}
.menu .list ul li{
display: inline-block;
margin-left: 10px;
}
.menu .list ul li a{
    text-decoration: none;
    color: black;
}
.cart{
    text-align: right;
    margin-top: 25px;
}
.menu .cart span{
    float: right;
}
.menu .cart span img{
    width: 25px;
}
.menu .cart ul li img{
width: 18px;
vertical-align: -6px;
margin-right: 8px;
}
.menu .cart ul li{
   display: inline-block;
   margin-right: 20px;
}
.menu .cart ul li a{
    text-decoration: none;
   display: inline-block;
   color: black;
   position: relative;
}
.menu .cart ul li a span::before{
    content: '';
    width: 0;
    height: 1px;
    background-color: black;
    position: absolute;
    bottom: -5px;
    left: 25px;
    transition: 0.5s ease all;
}
.menu .cart ul li a:hover span::before{
    width: 70%;
    transition: 0.5s ease all;
   
}
.menu .list ul li a span{
    position: relative;
}

.menu .list ul li span::before{
    content: '';
    width: 0;
    height: 1px;
    background-color: black;
    position: absolute;
    bottom: -5px;
    left: 0;
    transition: 0.5s ease all;
}
.menu .list ul li a:hover span::before{
    width: 100%;
    transition: 0.5s ease all;
   
}
.menu .side{
    width: 35%;
    height: 100vh;
    background-color:#fff;
    position: fixed;
    top: 0;
    right: -100%;
    padding: 10px;
}
.menu .side .picture img{
    width: 25%;
    float: left;
}
.menu .side form input{
    width: 100%;
   border: none;
   border-bottom: 1px solid black;
    padding: 10px;
    outline: none;
    margin-bottom: 20px;
    position: relative;
}
.menu .side .inner{
    margin-top: 15%;
}
.menu .side .inner img{
    margin-bottom: 30px;
}
.menu .side .inner p{
    margin-bottom: 30px;
}
.menu .side h3{
    margin-bottom: 20px;
}
.menu .side .picture img:hover{
opacity: 0.5;
cursor: pointer;
}
.menu .side form label{
    margin: 30px 0 20px 0;
    display: inline-block;
}
.menu .side form{
    position: relative;
}
.menu .side form button{
position: absolute;
bottom: 22px;
right: 5px;
outline: none;
cursor: pointer;
border: none;
background: none;
}
.menu .side ul{
    margin-top: 10px;
}
.menu .side ul li{
    margin-bottom: 10px;
    list-style: none;
    padding-right: 10px;
}
.menu .side ul li a{
    text-decoration: none;
    color: black;
}
.menu .side .close span{
    float: right;
    font-size: 28px;
    margin-right: 15px;
}
.menu .wrapper{
    width: 100%;
    height: 100vh;
    background: rgb(0, 0,0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    z-index: 999;
}




.addmenu{
    height: 340px;
    transition: 1s ease all;
}

.pos{
    top: 20%!important;
}

/* .sub1{
    padding: 20px;
    position: absolute;
    top: 14.4%;
    left: 0;
    right: 0;
    z-index: 30;
    width: 100%;
    height: 300px;
    background-color: white;
    display: none;
}
.sub1 img{
    width: 70%;
    margin: 50px auto;

}
.sub1 ul{
    margin: 50px 0;
}
.sub1 ul li{
    list-style: none;
   margin-bottom: 15px;
}
.sub1 ul li a{
    text-decoration: none;
    color: rgb(107, 106, 106);
}

.menu .list #sub-one:hover .sub1{
display: block;
} */


















@media only screen and (max-width:1200px){
    .menu .list ul{
        position: absolute;
       top: 100%;
       margin-top: 34px;
    }
    .menu .list ul li{
        display: block;
        padding-bottom: 40px;
    }
    .menu .cart ul{
display: none;
    }
    .menu .wrapper{
        display: none
    }
    .menu{
        border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    }
}

@media only screen and (max-width:992px){
    .menu .wrapper .side{
        width: 80%;
    }
}