.carousel{
    margin-top: 150px;
   
}


.carousel .titr{
    text-align: center;
    margin-bottom: 70px;

}
