.box{
   margin-top: 100px;
}

.box .boxes{
    padding: 10px;
   
}
.box .boxes .mini-box img{
width: 31px;
display: inline-block;
}
.box .boxes .mini-box{
    background-color: #f9eae4;
  height: 200px;
    padding: 10px 10px 10px 30px;
    
}
.box .back .mini-box{
    background-color: #e8f1f9;

}
.box .boxes .mini-box h3{
    display: inline-block;
    vertical-align: 10px;
    margin-left: 10px;
    padding-top: 43px;
}
.box .boxes .mini-box p{
    font-size: 15px;
    margin-top: 15px;
  
}