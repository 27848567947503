.CarouselText{
    margin-top: 100px;
}

.CarouselText .carousels-text{
text-align: center;
}
.CarouselText .carousels-text h3{
    font-size: 38px;
   
   overflow: auto;
}
.CarouselText .carousel-one{
   
    text-align: center;
   
   
}
.CarouselText  .all{
   height: 400px;
    padding: 20px;
    background-color: #fafafa;
}
.CarouselText .carousel-one .item{
width: 65%;
margin:0 auto;
}
.CarouselText .carousel-one .owl-carousel .owl-nav button.owl-next{
    position: absolute;
    top: 35%;
    font-size: 30px;
    right: 13%;
    outline: none;
}
.CarouselText .carousel-one .owl-carousel .owl-nav button.owl-prev{
    position: absolute;
    left: 13%;
    font-size: 30px;
    top: 35%;
    outline: none;
}
.CarouselText .carousel-two{
    margin-top: 50px;
}
.CarouselText .carousel-two .item img{
    opacity: 0.4;
}
.CarouselText .carousel-two .item img:hover{
    opacity: 1;
    cursor: pointer;
}

@media only screen and (max-width:480px){
    .CarouselText  .all{
        height: 542px;
    }
}