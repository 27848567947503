.footerbox{
    margin-top:80px;
   margin-bottom: 550px;
  
  
}

.footerbox .img-box img{
    width: 100%;
}
.footerbox .img-box #first{
width: 90%;
}
.footerbox .img-box #second{
    width: 45%;
    }
    .footerbox .img-box #third{
        width: 90%;
        }
.footerbox .space{
    padding: 0 15px 0 15px;
}
.footerbox .img-box{
    position: relative;
}
.footerbox .img-box .img{
    position: absolute;
    top: 34px;
     left: 17px;
}

@media only screen and (max-width:992px){
    .footerbox .img-box{
        margin-bottom: 50px;
    }
    .footerbox .img-box .img{
        top: 67px;
     
    }
    .footerbox{
           
        margin-bottom: 0;
       
       
     }
    
    @media only screen and (max-width:768px){
        

.footerbox .img-box #third{
    top: 10px;
}
.footerbox .img-box #first{
    top: 10px;
}
    }

    
}