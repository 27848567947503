.navbar{
    background-color: #fff1e5;
    padding: 13px;
}
.navbar .nav-text,.nav-login{
    width: 50%;
    float: left;
}
.navbar .nav-text p{
    font-size: 14px;
}
.navbar .nav-login{
    text-align: right;
}
.navbar .nav-login ul li{
display: inline-block;
margin-left: 30px;
}
.navbar .nav-login ul li a{
    text-decoration: none;
    color: black;
    font-weight: 100;
    font-size: 14px;
}
.navbar .nav-login ul li:hover a{
    opacity: 0.5;
}
@media only screen and (max-width:1200px){
          
    .navbar{
        display: none;
    }
}