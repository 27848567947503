.products .titr{
    text-align: center;
}
.products{
    margin-top: 130px;
}
.products .titr h3{
    font-size: 40px;
}
.products .titr p{
    letter-spacing: 4px;
    color: rgba(0, 0, 0, 0.5);
}
.products .titr ul li{
    display: inline-block;
    margin-right: 40px;

}
.products .titr ul li a{
    text-decoration: none;
    color: black;
}
.products .titr ul{
    margin-top: 40px;
}

.products .cart-pro .orginal:last-of-type{
    display: none;
}


@media only screen and (max-width:480px){
    .products .titr ul li {
       
        margin-right: 20px;
        font-size: 13px;
    }
    .products .titr h3 {
        font-size: 30px;
    }
}

