.blog{
    background-color: white;
    margin-bottom: 450px;
}
.blog .sidebar{
    padding: 0 10px;
}
.blog .sidebar .sidebar-search input{
    width: 100%;
    padding: 10px;
    border: none;
    border-bottom: 1px solid black;
   
    outline: none;
}
.blog .sidebar .sidebar-search{
    position: relative;
}
.blog .sidebar .sidebar-search button{
    padding: 5px;
    position: absolute;
    right: 0;
    top: 0;
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
}
.blog .sidebar .sidebar-search button i{
    font-size: 20px;
}

.blog .sidebar .paragraph{
    margin-top: 30px;
}
.blog .sidebar .paragraph h3{
    margin-bottom: 20px;
}
.blog .sidebar .paragraph p{
    line-height: 30px;
    font-size: 13px;
    margin-bottom: 20px;
}
.blog .sidebar .paragraph img{
    width: 100%;
}
.blog .sidebar .top-box img{
    width: 70px;
    height: 70px;
    float: left;
    margin-right: 15px;
   
    }
    .blog .sidebar .top-box p{
        margin-bottom: 50px;
    }
    .blog .sidebar #image2 img{
width: 100%;
margin: 60px 0 30px 0;
    }
    .blog #blog-story{
        position: relative;
    }

    .blog .owl-carousel .owl-nav{
        position: absolute;
        right:0;
        bottom: 20px;
    }
    .blog .owl-carousel .owl-nav button span{
        display: block;
        padding: 0px 15px;
        background-color: white;
    font-size: 35px;
    
    }
    .blog .owl-carousel .owl-nav button{
        outline: none;
    }
    .blog .blog-carousel{
        padding: 0 20px 0 20px;
    }
    .sidebar #side-para{
        margin: 20px 0 30px 0;
    }
    .sidebar #side-para h3{
        margin-bottom: 20px;
    }
    .sidebar #side-para p{
        line-height: 30px;
    }
    .blog .blog-carousel .discription h2{
        margin: 30px 0;

    }
    .blog .blog-carousel .discription p{
        text-align: justify;
    }
    .blog .blog-carousel #text-footer{
        margin-top: 30px;
        float: left;
    }
    .blog .blog-carousel #text-footer2{
float: right;
margin-top: 30px;
    }
    .blog .blog-carousel #text-footer2 i{
        font-size: 16px;
        padding-right: 10px;
    }
    .blog .blog-carousel .header-comment img{
        width: 25%;
float: left;
    }
    .blog .blog-carousel .header-comment{
        margin-top: 100px;
    }
    .blog .blog-carousel .header-comment .comment-text{
        margin: 23px 0 0 10px;
        float: right;
        width: 70%;
    }
    .blog .blog-carousel .comment{
        margin-top: 100px;
    }
    .blog .blog-carousel .comment h2{
        margin-bottom: 50px;
    }
    .blog .blog-carousel .comment .text-comment img{
float: left;
margin-right: 30px;
    }
    .blog .blog-carousel .comment .text-comment{
        margin-bottom: 80px;
    }
    .blog .blog-carousel .comment #text-comment{
        margin-left: 100px;
    }
    .blog .blog-carousel .comment .text-comment .question span{
font-size: 12px;
margin: 5px 0 15px 0;
display: block;
    }
    .blog .blog-carousel .comment .text-comment .question p{
        font-size: 14px;
    }
    .blog .blog-carousel .comment .text-comment .question button{
        background: none;
        border: none;
        float: right;
        color: black;
        font-weight: bold;
        cursor: pointer;
        outline: none;
        position: relative;
    }
    .blog .blog-carousel .comment .text-comment .question button::before{
        content: '';
        width: 100%;
        height: 1px;
        background-color: black;
        position: absolute;
        bottom: -5px;
       
        left:0;
        transition: 0.5s ease all;
    }
    .blog .blog-carousel .comment .text-comment .question button:hover::before{
        width: 0;
        transition: 0.5s ease all;
    }
    .blog .blog-carousel .form-footer input{
        width: 100%;
        border: none;
        border-bottom: 1px solid gray;
        padding: 10px;
        outline: none;
    }
    .blog .blog-carousel .form-footer h2{
        margin-bottom: 30px;
        margin-left: 15px;
    }
    .blog .blog-carousel .form-footer .form{
        padding: 0 15px;
        margin-bottom: 30px;
    }
    .blog .blog-carousel .form-footer .form textarea{
        width: 100%;
        background-color: #f6f6f6;
        border: none;
        padding: 20px;
    }
    .blog .blog-carousel .form-footer .form #submit{
        padding: 10px 30px;
        color: white;
        background-color: black;
        border: none;
        margin-top: 20px;
    }

    .blog .blog-carousel .comment  .form-footer{
        margin-top: 40px;
    }
    .blog .blog-carousel .comment  .form-footer #form-btn{
        background: none;
        border: none;
        float: right;
        color: black;
        font-weight: bold;
        cursor: pointer;
        outline: none;
        position: relative;
    }
    .blog .blog-carousel .comment  .form-footer #form-btn::before{
        content: '';
        width:100%;
        height: 1px;
        background-color: black;
        position: absolute;
        bottom: -5px;
        left:0;
        transition: 0.5s ease all;
    }
    .blog .blog-carousel .comment  .form-footer #form-btn:hover::before{
        width: 0;
        transition: 0.5s ease all;
    }
    .blog .blog-carousel .comment  .form-footer{
       display: none;
    }

    @media only screen and (max-width:768px){
        .blog .sidebar #image2{
            text-align: center;
        }
        .blog .sidebar #image2 img{
            width: 60%;
        }
        .blog .sidebar .sidebar-search{
            margin-top: 50px;
        }
        .blog .blog-carousel .comment #text-comment{
            margin-left: 0;
        }
    }

    @media only screen and (max-width:480px){
        .blog .blog-carousel .header-comment img{
            transform: translateY(70px);
        }
        .blog .blog-carousel .header-comment .comment-text p{
            font-size: 12px;
            margin: 10px 0;
        }
        .blog .blog-carousel #text-footer{
            font-size: 9px;
        }
        .blog .blog-carousel #text-footer2 i {
            font-size: 12px;
        }
        .blog .blog-carousel .discription h2{
            font-size: 18px;
        }
        .blog .blog-carousel .comment .text-comment img{
            width: 20%;
        }
        .blog .blog-carousel .comment .text-comment .question p{
            font-size: 12px;
        }
        .blog #container{
            width: 95%;
        }
    }
    @media only screen and (max-width:992px){
        .blog{
            margin-bottom: 0;
        }
    }