.footer{
   
    background-color: black;
    height: auto;
    margin-top: 60px;
 position: fixed;
 left: 0;
 bottom: 0;
 width: 100%;
 z-index: -1;
}

.footer h3{
    color: #f4f4f4;
}
.footer img:last-of-type{
    width: 80px;
}
.footer ul li{
    font-size: 14px;
    margin-bottom: 15px;
    list-style: none;
}
.footer ul {
    margin-top: 25px;
}
.footer ul li a{
    text-decoration: none;
    color: #f4f4f4;
}
.footer p{
    color:#f4f4f4 ;
    font-size: 14px;
    margin:20px 0;
}
.footer .list{
    margin-top: 130px;
}
.footer .list input{
    padding: 10px;
    background: none;
    border: none;
    border-bottom: 1px solid #f4f4f4;
    outline: none;
    color: white;
}
.footer .copyright{
    text-align: center;
    padding: 20px;
}
.footer .copyright a{
    text-decoration: none;
    color: #f4f4f4;
}



@media only screen and (max-width:992px){
    .footer{
   
       
     position:static;
     left: 0;
     bottom: 0;
    
     z-index: 0;
    }
}