.car .owl-carousel .owl-item img{
    width: 50%;
    height: 200px;
    margin: 0 auto;
}
.car{
    margin-bottom: 260px;
}
.car .owl-carousel .owl-item .item{
text-align: center;
}
.car .owl-carousel .owl-item .item h6 {
margin-top: 30px;
}
.car .owl-carousel .owl-nav button.owl-prev{
    position: absolute;
    left: 20px;
    font-size: 40px;
    outline: none;
}
.car .owl-carousel .owl-nav button.owl-next{
    position: absolute;
    right: 20px;
    font-size: 40px;
    outline: none;
}
.car .owl-carousel .owl-nav{
    margin-top: -200px;
}

.main{
    background-color: white;
}


@media only screen and (max-width:768px){
    .head .owl-carousel .owl-item .item h1{
        font-size: 36px;
        }
        .head .owl-carousel .owl-nav button span{
padding: 0 10px 0 10px;
        }
}