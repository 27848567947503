.biger-pic{
    /* background-image: url(./payments-img-2.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    height: 564px;
    width: 100%; */
    width: 100%;

}
.biger-pic img{
    width: 100%;
}
.price-box{
    background-color: white;
    margin-top: -5px;
    margin-bottom: 500px;
    padding-bottom: 30px;
}
.price-box .price-blue{
    width: 100%;
    height: auto;
    background-color: #f7f8f9;
    padding:30px;
}

.price-box .box{
    padding: 0 15px 0 15px;
}
.price-box .price-blue span{
  
    font-size: 20px;
    font-weight: bold;
}
.price-box .price-blue h2:not(span){
    font-size: 36px;

}
.price-box .price-blue p{
    margin: 15px 0 15px 0;
    line-height: 25px;
}
.price-box .price-blue ul li{
   list-style: url(./157822.svg);
   margin-bottom: 20px;
   list-style-position: inside;
}
.price-box .price-blue button{
    border: none;
    background: none;
    cursor: pointer;
    position: relative;
    font-size: 14px;
    font-weight: bold;
}
.price-box .price-blue button::before{
    content: '';
    position: absolute;
    bottom: -5px;
    
    left: 0;
    width: 100%;
    height: 1px;
    background-color: black;
    transition: 0.5s ease all;
}
.price-box .price-blue button:hover::before{
    width: 0;
    
  
    transition: 0.5s ease all;
}
.price-box .box #red{
    background-color: #fff4ec;
    position: relative;
}
.price-box .box #red::before{
    content: 'best';
    position: absolute;
    width: 49px;
    text-align: center;
    top: 11px;
    height: 22px;
    transform: rotate(90deg);
    right: 9%;
    background-color: black;
    font-weight:100 ;
    color: white;
    letter-spacing: 2px;
    align-self: center;
}










@media only screen and (max-width:992px){
    .price-box{
        margin-bottom: 0;
    }
}