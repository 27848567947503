
.news .cover{
    padding: 0 20px;
}

.news .background{
    
    background-image: url(./h-8-img-4.jpg);
    width: 100%;
    height: 374px;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    background-position: center;
}
.news .background h3{
    padding-top: 60px;
    font-size: 36px;
}
.news .background input{
    border: none;
    border-bottom: 1px solid black;
    text-align: center;
    outline: none;
    margin-top: 60px;
    padding: 5px;
    width: 70%;
}
.news .background button{
    padding: 12px 40px;
    background-color: black;
    color: white;
    border: none;
    margin-top: 40px;
}
.news .img .first-img img{
width: 100%;
height: 374px;
}
.news .img .first-img{
    position: relative;
}
.news .img .pad{
    padding: 0 10px;
}
.news .img .pad .text{
position: absolute;
top: 8%;
left: 10%;
}
.news .img .pad .text p{
    position: relative;
}
.news .img .pad .text p::before{
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 0;
    height: 1px;
    background-color: black;
    transition: 0.5s ease all;
}

.news .img .pad .text:hover p::before{
    width: 100%;
    transition: 0.5s ease all;
}



@media only screen and (max-width:480px){
    .news .background{
        margin-bottom: 50px;
       
    }
    .news .background h3 {
        padding-top: 81px;
        font-size: 25px;
    }

}
@media only screen and (min-width:480px) and (max-width:768px){
    .news .background{
        margin-bottom: 50px;
       
    }
    
    
}
@media only screen and (min-width:768px) and (max-width:992px){
    .news .background{
        margin-bottom: 50px;
        height: 500px;
    }
    .news .background h3 {
        padding-top: 100px;
    }
}