.story{
    background-color: white;
    margin-bottom: 474px;
}
.story .first-img img{
    width: 100%;
    height: 341px;
}
.story .slider-story .item img{
    width: 100%;
   
    height: 687px;
}
.story .owl-carousel .owl-nav{
    position: absolute;
    right:0;
    bottom: 0;
}
.story .owl-carousel .owl-nav button span{
    display: block;
    padding: 15px 20px;
    background-color: white;
font-size: 35px;

}
.story .owl-carousel .owl-nav button{
    outline: none;
}
.story .slider-story .item .pic{
    position: relative;
}
.story .slider-story .item .sub{
    position: absolute;
    bottom:10%;
    left: 10%;
}
.story .slider-story .item .sub h3{
    font-size: 40px;
    color: black;
}

.story .slider-story .item .sub button{
   padding: 5px;
    border: none;
    position: relative;
    cursor: pointer;
   background: none;
    outline: none;
    margin-top: 20px;
}
.story .slider-story .item .sub button::before{
    content: '';
    width: 0;
    height: 1px;
    background-color: black;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: 0.5s ease all;
}

.story .slider-story .item .sub button:hover::before{
    width: 100%;
    transition: 0.5s ease all;
}
.story .first-col img{
    width: 100%;
   height: 341px;
}
.story .second-col img{
width: 100%;
height:341px;
}
.story #space1{
margin-top: -20px;
}
.story .hover .pos{position: relative; text-align: center;overflow: hidden;}
.story .hover .pos .mark{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    visibility: hidden;
   
}
.story .hover .pos:hover .mark{
visibility: visible;
transition: 0.8s ease all;
}
.story .hover img{
    transition: 0.5s ease all;
    cursor: pointer;
}
.story .hover .pos:hover img{
opacity: 0.3;
transition: 0.5s ease all;
transform:translateX(-8px);
}
.story .hover .pos .mark h6{
    font-size: 18px;
}
.story .hover .pos .mark ul{
    border: 1px solid black;
    padding: 10px;
    margin-top: 15px;
}
.story .hover .pos .mark ul li{
    display: inline-block;
    margin-right: 10px;
    list-style: none;
}
.story .hover .pos .mark ul li a{
text-decoration: none;
color: black;
}
.story .owl-carousel{
    height: 687px;
}
.story .owl-carousel .owl-stage{
    height: 669px;
}

@media only screen and (max-width:992px){
    .story{
        margin-bottom: 0;
    }
    .story .first-img img{
       
        height: 500px;
    }
    .story .first-col img{
       
       height: 500px;
    }
    .story .second-col img{
  
    height:500px;
    }
}