.header{
    
    background-color: white;
}


.header .pic{
    height: 100%;
    width: 100%;
   position: relative;
  
}


.header .pic img{
    width: 100%;
    height: 100%;
}
.header .sub{
    position: absolute;
    bottom:15%;
    left: 10%;
}
.header .sub h1{
    font-size: 85px;
    color: white;
}
.header .sub p{
    color: white;
}
.header .sub button{
    padding: 13px 45px 13px 45px;
    border: none;
    position: relative;
    cursor: pointer;
    background-color: white;
    outline: none;
    margin-top: 20px;
}
.header .sub button::before{
    content: '';
    width: 100%;
    height: 1px;
    background-color: white;
    position: absolute;
    bottom: -5px;
    left: 0;
}

.header .owl-carousel .owl-nav{
    position: absolute;
    right: 0;
    bottom: 20px;
}
.header .owl-carousel .owl-nav button span{
    display: block;
    padding: 15px 20px;
    background-color: white;
font-size: 35px;

}
.header .owl-carousel .owl-nav button{
    outline: none;
}
.header .one{
    padding: 0 15px 0 15px;
    cursor: pointer;
    position: relative;
}
.header .two{
    padding: 0 15px 0 15px;
    position: relative;
}

.header .one .picture{
position: relative;
height: 300px;

margin-bottom: 20px;
}

.header .one .picture img{
    width: 98%;
    height: 98%;
    margin: 2% 0 0 2%;
    transition: 0.5s ease all;
}
.header .two .picture2 img{
    width: 98%;
    height: 98%;
    margin: 2% 0 0 2%;
    transition: 0.5s ease all;
}
.header .one .picture::before{
    content: '';
    width: 100%;
    height: 100%;
    background-color:#e4f1f6;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.header .two .picture2::before{
    content: '';
    width: 100%;
    height: 100%;
    background-color:#fcdddc;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}
.header .two .picture2{
    position: relative;
    height: 300px;
    
    }

    .header .mini-slider .one:hover img{
        width: 100%;
        height: 100%;
        margin: 0 0 0 0;
        transition: 0.5s ease all;
    }
    .header .mini-slider .two:hover img{
        width: 100%;
        height: 100%;
        margin: 0 0 0 0;
        transition: 0.5s ease all;
    }

    .header .mini-slider .one .caption{
        position: absolute;
        bottom: 15%;
        left: 15%;
        cursor: pointer;
    }
    .header .mini-slider .two .caption{
        position: absolute;
        bottom: 15%;
        left: 15%;
       cursor: pointer;
    }
    .header .mini-slider .two .caption p{
        position: relative;
        
    }
    .header .mini-slider .two .caption p::before{
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        left: 0;
        bottom: -20%;
        background-color: black;
        transition: 0.5s ease all;
    }
    .header .mini-slider .two .caption:hover p::before{
        width: 0;
        transition: 0.5s ease all;
    }

    .header .mini-slider .one .caption p{
        position: relative;
        
    }
    .header .mini-slider .one .caption p::before{
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        left: 0;
        bottom: -20%;
        background-color: black;
        transition: 0.5s ease all;
    }

    .header .mini-slider .one .caption:hover p::before{
        width: 0;
        transition: 0.5s ease all;
    }