.prods{
    margin-top: 50px;
}

.prods img{
    width: 56%;
    height: 150px;
}
.prods .distance{
    padding: 0 15px 0 15px;
    text-align: center;
}
.prods .distance h6{
    margin-top: 30px;
}